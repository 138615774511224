#wpadminbar #wp-admin-bar-my-sites>.ab-item,
#wpadminbar #wp-admin-bar-site-name>.ab-item {
    padding-left: 40px
}

#dn_option-contact_maps img {
    max-width: none
}

#wpadminbar #wp-admin-bar-my-sites>.ab-item:before,
#wpadminbar #wp-admin-bar-site-name>.ab-item:before {
    content: ''!important;
    width: 35px;
    height: 28px;
    background-image: url(./img/static/site-logo.png)!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-color: transparent!important;
    z-index: 10;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 2px;
    left: 2px
}

#dashboard_right_now li.comment-count {
    display: none!important
}

#digital_noir_instruction_metabox ul {
    list-style: disc;
    margin-left: 0.5em;
    padding-left: 0.8em;
    line-height: 1.2em
}

#digital_noir_instruction_metabox .inside h2,
#digital_noir_instruction_metabox .inside h3,
#digital_noir_instruction_metabox .inside h4 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0px;
    padding-bottom: 5px
}

#digital_noir_instruction_metabox .inside h3 {
    font-size: 16px;
}

#digital_noir_instruction_metabox .inside h4 {
    font-size: 14px
}

#digital_noir_instruction_metabox .inside p {
    margin-top: 0
}

#digital_noir_instruction_metabox a {
    text-decoration: underline
}

#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow,
#adminmenu .wp-menu-arrow div,
#adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top,
.folded #adminmenu li.wp-has-current-submenu {
    background: $dn-primary-color;
}

#adminmenu li.menu-top:hover,
#adminmenu li.opensub>a.menu-top,
#adminmenu li>a.menu-top:focus,
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top>a:focus,
#adminmenu li a:focus div.wp-menu-image:before,
#adminmenu li.opensub div.wp-menu-image:before,
#adminmenu li:hover div.wp-menu-image:before {
    color: $dn-primary-color;
}

// Hides customise menu under appearance (disabled through PHP)
.hide-if-no-customize {
    display: none;
}

body.roles-administrator {
    .hide-if-no-customize {
        display: block;
    }
}

/////////////////////
// ACF Block stuff //
/////////////////////
.acf-fc-popup .preview {
    position: absolute;
    right: 100%;
    margin-right: 0px;
    top: 0;
    background: #383c44;
    min-height: 100%;
    border-radius: 5px;
    align-content: center;
    display: grid;
}

.acf-fc-popup .preview .inner-preview {
    padding: 10px;
}

.acf-fc-popup .preview img {
    display: block;
}

.acf-flexible-content .layout .acf-fc-layout-handle{
    background-color: $dn-primary-color;
    color: #fff!important
}

.acf-repeater .acf-actions{
    text-align: center 
}

.postbox.acf-postbox h2 {
    padding: 15px 20px !important;
    color: #fff;
    background: $dn-primary-color;
}

// Clean up ACF & Wordpress buttons
.wp-core-ui .button-primary {

    background: $dn-primary-color;
    border-color: darken( $dn-primary-color, 5%) darken( $dn-primary-color, 10%) darken( $dn-primary-color, 10%);
    -webkit-box-shadow: 0 1px 0 darken( $dn-primary-color, 10%);
    box-shadow: 0 1px 0 darken( $dn-primary-color, 10%);
    color: #fff;
    text-decoration: none;
    text-shadow: 0 -1px 1px darken( $dn-primary-color, 10%), 1px 0 1px darken( $dn-primary-color, 10%), 0 1px 1px darken( $dn-primary-color, 10%), -1px 0 1px darken( $dn-primary-color, 10%);

    &.focus, &.hover, &:focus, &:hover {
        background: lighten( $dn-primary-color, 3% );
        border-color: darken( $dn-primary-color, 10%);
        color: #fff;
    }

    &.active, &:active {
        background: darken( $dn-primary-color, 5%);
        border-color: darken( $dn-primary-color, 10%);
        -webkit-box-shadow: inset 0 2px 0 darken( $dn-primary-color, 10%);
        box-shadow: inset 0 2px 0 darken( $dn-primary-color, 10%);
        vertical-align: top;
    }
}

.acf-repeater .acf-actions {
    text-align: right;
}

.wp-core-ui .button-primary-disabled, .wp-core-ui .button-primary.disabled, .wp-core-ui .button-primary:disabled, .wp-core-ui .button-primary[disabled] {
    color: lighten( $dn-primary-color, 12.5%)!important;
    background: lighten( $dn-primary-color, 10%)!important;
    border-color: lighten( $dn-primary-color, 7.5%)!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    text-shadow: 0 -1px 0 rgba(0,0,0,.1)!important;
    cursor: default;
    color: white!important;
}

// Gallery back-end styling
.acf-gallery-side-inner .compat-field-gallery_video{
    display: table-row!important
}
.compat-field-gallery_video{
    display: none!important; 
}

/////////////////////
// ACF Focal Point //
/////////////////////

@media all and (max-width: 900px) {
    .mfp-arrow {
      -webkit-transform: scale(0.75);
      transform: scale(0.75);
    }
    .mfp-arrow-left {
      -webkit-transform-origin: 0;
      transform-origin: 0;
    }
    .mfp-arrow-right {
      -webkit-transform-origin: 100%;
      transform-origin: 100%;
    }
    .mfp-container {
    //   padding-left: $mfp-popup-padding-left-mobile;
    //   padding-right: $mfp-popup-padding-left-mobile;
    }
  }
  
  .acf-focal_point-canvas {
      width: 100%;
      cursor: crosshair;
  }
  
  .acf-focal_point-image {
      display: none;
  }
  .acf-focal_point .has-image {
      display: none;
      position: relative;
  }
  .acf-focal_point .acf-button-delete {
      position: absolute;
      top: -11px;
      right: -11px;
      z-index: 1;
      cursor: pointer;
  }
  .acf-focal_point .acf-icon {
      opacity: 0;
      transition: .25s ease-in-out;
  }
  .acf-focal_point .acf-icon:hover {
      color: #D54E21;
  }
  .acf-focal_point .has-image:hover .acf-icon {
      opacity: 1;
  }
  .acf-focal_point.active .no-image {
      display: none;
  }
  
  .acf-focal_point.active .has-image {
      display: block;
      float: left;
  }